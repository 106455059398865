import { React, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { validateDocs } from '../../store/slices/validar-documento'
import { useValidarDocumentoMutation } from '../../store/api/endpoints/validar-documento'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Input from '../../components/elementos/Input'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import Loading from '../elementos/Loading'
import SuccessValidateDoc from '../elementos/SuccessValidateDoc'
import ErrorValidateDoc from '../elementos/ErrorValidateDoc'


const ValidarDocumento = () => {

    const [documento, setDocumento] = useState("")
    //const [errorMessage, setErrorMessage] = useState("El documento no pertenece a un abonado activo.")

    const [triggerValidacionDocumento, { data: documentoConfig, isLoading: isLoadingValidarDocumento, error: errorValidarDocumento, isError: isErrorValidarDocumento, isSuccess: isSuccessValidarDocumento }] = useValidarDocumentoMutation()
    const dispatch = useDispatch()

    const submitForm = async (e) => {

        e.preventDefault()

        try {
            const validarDocumento = await triggerValidacionDocumento({ documento }).unwrap()
            dispatch(validateDocs(validarDocumento))
            setDocumento("")
        } catch (err) {
            console.log(err)
        } 

    }

    if (errorValidarDocumento) return (<ErrorValidateDoc error={errorValidarDocumento.message} />)
    
    if (isLoadingValidarDocumento) return (<Loading />)

    if (isSuccessValidarDocumento) return (<SuccessValidateDoc nombre={documentoConfig.nombre} dni={documentoConfig.dni} cuit={documentoConfig.cuit} />)
        
    //return (<ErrorValidateDoc error={errorMessage} />)
    
    return (
        <>
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <form onSubmit={submitForm}>
                                <h2 style={{ letterSpacing: '1px', fontWeight: '600' }}>Validar Documento</h2>
                                <Input
                                    tituloMini="Por favor ingrese un CUIT o DNI para validarlo"
                                    type="tel"
                                    value={documento}
                                    onChange={setDocumento}
                                    icon="pi-id-card"
                                    required
                                />
                                <br />
                                <br />
                                {/*<h3 className="error">{errorMessage ? errorMessage : ''}</h3> */}
                                <p><Button type="submit" variant="outlined" color="success" endIcon={<KeyboardArrowRightIcon />}>
                                    Validar 
                                </Button></p>
                            </form>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}

export default ValidarDocumento