import { configureStore  } from "@reduxjs/toolkit"

import { setupListeners } from '@reduxjs/toolkit/query'
import { tiendasApi } from './api/api'

import { feedbackSlice } from './slices/feedback'
import { cronogramasSlice } from './slices/cronogramas'
import { descuentosSlice } from './slices/descuentos'
import { productosSlice } from './slices/productos'
import { recuperarClaveSlice } from './slices/recuperar-clave'
import { recuperarClaveTokenSlice } from './slices/recuperar-clave-token'
import { loginSlice } from './slices/auth'
import { refreshTokenSlice } from './slices/refresh-token'
import { validarDocumentoSlice } from "./slices/validar-documento"



export const store = configureStore({
    reducer: {
        [tiendasApi.reducerPath]: tiendasApi.reducer,

        feedback: feedbackSlice.reducer,
        cronogramas: cronogramasSlice.reducer,
        descuentos: descuentosSlice.reducer,
        productos: productosSlice.reducer,
        recuperarClave: recuperarClaveSlice.reducer,
        recuperarClaveToken: recuperarClaveTokenSlice.reducer,
        login: loginSlice.reducer,
        refreshToken: refreshTokenSlice.reducer,
        validarDocumento: validarDocumentoSlice.reducer


    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(tiendasApi.middleware),
});

setupListeners(store.dispatch);