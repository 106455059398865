import { tiendasApi } from '../api'

export const validarDocumentoSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        validarDocumento: builder.mutation({
            query: ({documento}) => ({
                url: 'web/public/validar-suscripto',
                method: 'POST',
                body: { "documento": `${documento}` }
            }),
            transformResponse: (response) => {
                if (response?.message) {
                  throw new Error(response.message);
                }
                return response;
            },
        })
    })
})

export const {
    useValidarDocumentoMutation
} = validarDocumentoSlice