import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Menu from './components/elementos/Menu'

//Componentes Vistas
import Home from './components/vistas/Home'
import NuevoSuscriptor from './components/vistas/NuevoSuscriptor'
import SuscriptoOk from './components/vistas/SuscriptoOk'
import Contacto from './components/vistas/Contacto'
import Cronograma from './components/vistas/Cronograma'
import Descuentos from './components/vistas/Descuentos'
import RecuperarClave from './components/vistas/auth/RecuperarClave'
import RecuperarClaveToken from './components/vistas/auth/RecuperarClaveToken'
import Perfil from './components/vistas/Perfil'
import PageNotFound from './components/vistas/PageNotFound'
import ValidarDocumento from './components/vistas/ValidarDocumento'

import RequireAuth from './components/vistas/auth/RequireAuth'
import Login from './components/vistas/auth/Login'


export default function Router() {



    return (
        <BrowserRouter>

            <Menu />
            
                <Routes > 
                    <Route element={<RequireAuth />}>
                        <Route path="*" element={<PageNotFound />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/nuevo-suscriptor" element={<NuevoSuscriptor />} />
                        <Route path="/public/validar-documento" element={<ValidarDocumento />} />
                        <Route path="/suscripto-ok/:url" element={<SuscriptoOk />} />
                        <Route path="/contacto" element={<Contacto />} />
                        <Route path="/cronograma" element={<Cronograma />} />
                        <Route path="/descuentos" element={<Descuentos />} />
                        <Route path="/mi-perfil" element={<Perfil />} />
                        <Route path="/recuperar-clave" element={<RecuperarClave />} />
                        <Route path="/recuperar-clave/:token" element={<RecuperarClaveToken />} />
                    </Route>
                </Routes>
       


        </BrowserRouter >
    );

}