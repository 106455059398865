import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

export default function SuccessValidateDoc({nombre, dni, cuit }) {

    const navigate = useNavigate()

    const returnToHome = () => {

        navigate("/")

    }

    return (

        <>
             <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <p align="center">
                                <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '2rem' }}></i>
                                <p>¡Documento Validado!</p>
                                <p>El abonado es <span>{nombre}</span> y se encuentra activo en RED CAFIRA</p>
                                <p>El DNI es {dni} y el CUIT es {cuit}</p>
                                <p><Button type="submit" variant="outlined" color="success" onClick={returnToHome} endIcon={<CheckCircleOutlineIcon />}>
                                    ENTENDIDO 
                                </Button></p>
                            </p>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}