import { createSlice } from "@reduxjs/toolkit"


export const validarDocumentoSlice = createSlice({
    name: 'validarDocumento',
    initialState: {
        nombre: null,
        dni: null,
        cuit: null
    },
    reducers: {
        validateDocs: (state, action) => {
            state.nombre = action.payload.nombre
            state.dni = action.payload.dni
            state.cuit = action.payload.cuit
        }
    }
});

export const { validateDocs } = validarDocumentoSlice.actions

